import IframeBridge, {
  GuestToHostNavigateInternal,
  IframeGuestToHostCommandPayload,
} from '@elemental/iframe-bridge';

import redux from '../redux';

const onStartupError = (
  dispatch: typeof redux.store.dispatch,
  errorMessage: string,
) => {
  dispatch(
    redux.guestApp.startupError(
      `Unexpected error while loading the application: ${errorMessage}`,
    ),
  );
};

/** translate pageName to a DashboardUI URI, containing placeholders */
const pageNameToURI = (pageName: string) => {
  let uri = '';
  switch (pageName) {
    case 'sign_in':
      uri = '/sign_in';
      break;
    case 'machineDetails':
      uri = '/managed_machines/{{machineUuid}}';
      break;
    default:
      console.warn(
        `internal navigation requested from guest - unsupported page ${pageName}`,
      );
  }

  return uri;
};

export const handleInternalPageOpen = (
  message: GuestToHostNavigateInternal,
) => {
  const { props } = message;
  let uri = pageNameToURI(props.pageName);
  Object.keys(props.pageProps).forEach((pagePropKey) => {
    const regExp = new RegExp(`{{${pagePropKey}}}`, 'g');
    uri = uri.replace(regExp, props.pageProps[pagePropKey]);
  });

  if (!uri) {
    return;
  }

  if (props.replaceActivePage) {
    window.location.pathname = uri;
  } else {
    window.open(
      uri,
      props.options.target ?? '_blank',
      props.options.features ?? '',
    );
  }
};

export const handleBackdrop = (backdropActive: boolean) => {
  if (backdropActive) {
    document.body.classList.add('guest-app-backdrop-active');
  } else {
    document.body.classList.remove('guest-app-backdrop-active');
  }
};

const WebAppMessaging = {
  adapter: IframeBridge.HostAdapter({
    baseURL: window.location.href,
    loggerEnabled: false,
  }),
  onGuestMessageReceived: (
    dispatch: typeof redux.store.dispatch,
    payload: IframeGuestToHostCommandPayload,
  ) => {
    const { message } = payload;
    const { cmd } = message;

    if (cmd === 'window.internalPageOpen') {
      handleInternalPageOpen(message);
    } else if (cmd === 'app.backdrop') {
      handleBackdrop(message.props.backdropActive);
    }
  },
  onStartupError,
};

export default WebAppMessaging;
